import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import {
	AppScanner,
	aboutImg2,
	aboutapp,
	aboutbutter,
	aboutegg,
	aboutflour,
	aboutfran,
	aboutkozeCookies,
	aboutkozecomb1,
	aboutkozecomb2,
	aboutkozecomb3,
	aboutkozecomb4,
	aboutkozecomb5,
	aboutkozecomb6,
	aboutkozeshake,
	aboutlocation,
	aboutlocdetail,
	aboutpageimg,
	appleImg,
	fruitimg,
	googleImg,
	icecubes,
	sugar,
} from "../../constant";
import "../../assets/css/about.css";
import { Link } from "react-router-dom";
import { FullpageNavigation } from "@ap.cx/react-fullpage";

const About = () => {
	const [close, Setclose] = useState(false);
	const aboutKozeComb = [
		{
			id: 1,
			img: aboutkozecomb1,
		},
		{
			id: 2,
			img: aboutkozecomb2,
		},
		{
			id: 3,
			img: aboutkozecomb3,
		},
		{
			id: 4,
			img: aboutkozecomb4,
		},
		{
			id: 5,
			img: aboutkozecomb5,
		},
		{
			id: 6,
			img: aboutkozecomb6,
		},
	];
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Header Setclose={Setclose} close={close} />
			{/* about sec starts here */}

			<section className="aboutsec">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-page-img-wrapper wow animate__animated animate__fadeInLeft">
								<figure>
									<img
										src={aboutpageimg}
										className="img-fluid"
										alt={aboutpageimg}
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-page-content-wrapper ms-lg-4 wow animate__animated animate__fadeInRight">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Served With A<span>Great Taste</span>
								</h2>
								<p>
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim facilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vitae aliquet nec ullamcorper.
								</p>
								<p>
									vel eros. At quis risus sed vulputate odio. Turpis massa
									tincidunt dui ut ornare lectus sit amet est. Ut pharetra sit
									amharetra diam sit am
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="banner-social-links-wrapper">
								<ul>
									<li>
										<Link to="/">FACEBOOK</Link>
									</li>
									<li>
										<Link to="/">INSTAGRAM</Link>
									</li>
									<li>
										<Link to="/">YOUTUBE</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* about sec ends here */}
			{/* about kozze shake starts here */}
			<section className="about-koze-shake">
				<div className="container">
					<div className="row align-items-center pb-5">
						<div className="col-lg-5">
							<div className="about-koze-head-wrapper wow animate__animated animate__fadeInDown">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									About <span>Koze Shake</span>
								</h2>
							</div>
						</div>
						<div className="col-lg-2"></div>
						<div className="col-lg-5">
							<div className="about-koze-head-wrapper wow animate__animated animate__fadeInRight">
								<p>
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bib endum enim facilisis gravida neque convallis.
									Pharetra diam sit
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="about-franchise-wrapper">
								<div className="about-franchise-content-wrapper wow animate__animated animate__fadeInLeft w-75">
									<h4>
										Register As <span>Franchise</span>
									</h4>
									<p>
										Placerat orci nulla pellentesque dignissim enim sit amet
										venenatis. Bibendum enim facilisis gravida neque
									</p>
									<Link to="/koze-franchise" className="btn">
										<span>Register</span>
										<span className="btn-icon">
											<i className="fa-solid fa-angle-right"></i>
										</span>
									</Link>
								</div>
								<div className="about-franchise-img-wrapper">
									<img src={aboutfran} className="img-fluid" alt={aboutfran} />
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-location-card-wrapper">
								<div className="about-location-content-wrapper wow animate__animated animate__fadeInLeft">
									<h4>
										Order From <span>Location</span>
									</h4>
									<p>
										Placerat orci nulla pellentesque dignissim enim sit amet
										venenatis
									</p>
								</div>
								<div className="about-lo-img-wrapper">
									<img
										src={aboutlocation}
										className="img-fluid"
										alt={aboutlocation}
									/>
								</div>
							</div>
							<div className="about-Mobile-card-wrapper">
								<div className="about-Mobile-content-wrapper wow animate__animated animate__fadeInRight">
									<h4>
										Order From <span>Mobile App</span>
									</h4>
									<p>
										Placerat orci nulla pellentesque dignissim enim sit amet
										venenatis
									</p>
									<div className="buttons-wrapper">
										<Link className="apple-btn" to={"/"}>
											<img src={appleImg} className="img-fluid" />
										</Link>
										<Link className="apple-btn" to={"/"}>
											<img src={googleImg} className="img-fluid" />
										</Link>
									</div>
								</div>
								<div className="about-Mobile-img-wrapper">
									<img src={aboutapp} className="img-fluid" alt={aboutapp} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* about kozze shake ends here */}
			{/* about location detail starts here */}
			<section className="about-location-detail">
				<div className="container-fluid ps-0">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-loc-detail-img-wrapper">
								<figure>
									<img src={aboutlocdetail} className="img-fluid" alt="" />
								</figure>
								<div className="scanner-main-wrapper">
									<div className="scan-me-wrapper">
										<div className="scan-img-wrapper">
											<div className="scanner-heading-wrapper">
												<h6>
													Scan <span>Me</span>
												</h6>
											</div>
											<img src={AppScanner} className="img-fluid" alt="" />
										</div>
										<div className="scan-img-wrapper">
											<div className="scanner-heading-wrapper">
												<h6>
													Scan <span>Me</span>
												</h6>
											</div>
											<img src={AppScanner} className="img-fluid" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-loc-detail-content-wrapper">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Location <span>Details</span>
								</h2>
								<p>
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim fac ilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vitae aliquet nec ullamcorper.haretra diam sit
									ametamet nisl t adipiscing bibendum est.dignissim enim sit
									amet venenatis. Bibendum eni enim
								</p>
								<Link to="/koze-franchise" className="btn">
									<span>Register As Franchise</span>
									<span className="btn-icon">
										<i className="fa-solid fa-angle-right"></i>
									</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* about location detail ends here */}
			{/* about koze shake starts here */}
			<section className="about-koze-shake">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-koze-shake-wrapper me-lg-4">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Koze <span>Shakes</span>
								</h2>
								<p>
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim facilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vitae aliquet nec ullamcorper.
								</p>
								<p>
									vel eros. At quis risus sed vulputate odio. Turpis massa
									tincidunt dui ut ornare lectus sit amet est. Ut pharetra sit
									amharetra diam sit am
								</p>
								<div className="about-koze-shake-items-wrapper">
									<ul>
										<li>
											<div className="about-list-wrap">
												<img src={fruitimg} className="img-fluid" alt="" />
												<h6>Fruits</h6>
											</div>
										</li>
										<li>
											<div className="about-list-wrap">
												<img src={sugar} className="img-fluid" alt="" />
												<h6>Sugar</h6>
											</div>
										</li>
										<li>
											<div className="about-list-wrap">
												<img src={icecubes} className="img-fluid" alt="" />
												<h6>Ice Cubes</h6>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-koze-shake-img-wrapper">
								<figure>
									<img
										src={aboutkozeshake}
										className="img-fluid"
										alt={aboutkozeshake}
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* about koze shake ends here */}
			{/* about koze cookies starts here */}
			<section className="about-koze-cookies">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-koze-cookies-img-wrapper">
								<figure>
									<img
										src={aboutkozeCookies}
										className="img-fluid"
										alt={aboutkozeCookies}
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-koze-cookies-wrapper ms-lg-4">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Koze <span>Cookies</span>
								</h2>
								<p>
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim fac ilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vitae aliquet nec ullamcorper.haretra diam sit
									ametamet nisl t adipiscing bibendum est.dignissim enim sit
									amet venenatis. Bibendum eni enim
								</p>
								<div className="about-koze-cookies-items-wrapper ">
									<ul>
										<li>
											<div className="about-list-wrap">
												<img src={aboutflour} className="img-fluid" alt="" />
												<h6>Flour</h6>
											</div>
										</li>
										<li>
											<div className="about-list-wrap">
												<img src={aboutegg} className="img-fluid" alt="" />
												<h6>Egg</h6>
											</div>
										</li>
										<li>
											<div className="about-list-wrap">
												<img src={aboutbutter} className="img-fluid" alt="" />
												<h6>Butter</h6>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* about koze cookies ends here */}
			{/* about koze combos starts here */}
			<section className="about-koze-combo-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-5">
							<div className="about-koze-combo-sec-content-wrapper">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Koze <span>Combos</span>
								</h2>
								<p>
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim facilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vitae aliquet nec ullamcorper.
								</p>
								<p>
									{" "}
									vel eros. At quis risus sed vulputate odio. Turpis massa
									tincidunt dui ut ornare lectus sit amet est. Ut pharetra sit
									amharetra diam sit am
								</p>
								<Link to="/our-menu" className="btn">
									<span>Our Menu</span>
									<span className="btn-icon">
										<i className="fa-solid fa-angle-right"></i>
									</span>
								</Link>
							</div>
						</div>
						<div className="col-lg-7">
							<div className="about-koze-combo-img-wrappers">
								<ul>
									{aboutKozeComb.map((data) => {
										return (
											<>
												<li key={data?.id}>
													<div className="koze-como-img-wrapper">
														<img
															src={data?.img}
															className="img-fluids"
															alt={data?.id}
														/>
													</div>
												</li>
											</>
										);
									})}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* about koze combos ends here */}
			<Footer />
		</>
	);
};

export default About;
